.logo{
   margin: .5em 10px 1em 5px;
}

.user {
    margin-top: .25em;
    margin-bottom: 1em;
    bottom: 0;
}

.nav-link {
    margin-top: .5em;
    margin-bottom: .5em;
}