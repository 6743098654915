.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.home-page.banner5-page{
  height: unset !important;
}

.blank-logo{
  width: auto;
  height: 200px;
  background-image:var(--logo-url);
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 44px;
}
.ant-spin-dot-item {
  background-color: var(--alternating-color);
}
.ant-spin-text { color: var(--primary-color); }
.spinner{
  width: auto;
  height: 200px;
  margin-top: 44px;
  display: block;
}

.home-logo{
  width: auto;
  height: 200px;
  background-image:var(--logo-url);
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 44px;
}

.login-logo{
  width: auto;
  height: 150px;
  background-image:var(--logo-url);
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* .ant-btn-primary{  
  background: #79d800;
  border-color: #15be00;
}

.ant-btn{  
  border-color: #15be00;
}

.ant-btn-primary:hover{  
  background: #6bb35c;
  border-color: #64c258;
  color: white !important;
}

.ant-btn:hover{  
  border-color: #6abe5f;
  color:#15be00;
}

.ant-btn-primary:focus{  
  background: #7fd46e;
  border-color: #64c258;
}

.ant-btn:focus{  
  border-color: #6abe5f;
  color:#15be00;
}  */

.ant-input:focus{  
  border-color: #64c258;
  box-shadow: 0 0 0 2px rgba(24, 255, 24, 0.2);
}

.ant-input:hover{  
  border-color: #64c258;
  --antd-wave-shadow-color:#64c258 ;
}

.ant-input-affix-wrapper:focus{
  border-color: #64c258 !important;
  box-shadow: 0 0 0 2px rgba(24, 255, 24, 0.2) !important;
}

.ant-input-affix-wrapper:hover{  
  border-color: #64c258 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
