.leaf-icon{
  width: auto;
  height: 85px;
  background-image:var(--icon-url);
  background-position-x: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.anticon-menu-fold{
  font-size: 2em;
  float:right;
  color: #000;
}

.ant-layout-sider-trigger{
  background-color: var(--text-color);
}


.ant-table-content{
  overflow: auto;
}

.ant-picker {
  width: 100%;
}