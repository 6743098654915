

.action-search-buttons {
    margin-top: 5em;
    margin-bottom: .5em;
}

.action-framework-buttons {
    margin-top: 2em;
}

.action-framework-button {
    margin-top: .35em;
    margin-bottom: .2em;
}

.action-search-buttons{
    margin-top: 8px;
}
.ant-select-show-search{
    margin-top: 8px;
}

.interestPo {
    color:white!important;
    font-size:20px!important;
    float:right!important;
    cursor:pointer!important;
}

.ant-collapse-content-box {
    padding: 0 !important;
    margin-top: 0 !important;
}

.action-framework-buttons {
    margin-top: 0 !important;
}

.ant-collapse-expand-icon {
    color: var(--white-color)
}
.ant-collapse .ant-collapse-item{
    border: 1px solid var(--secondary-color);
}

.ant-collapse-content{
    border: 0px solid red;
}

.ant-collapse-icon-position-start {
    border: 1px solid var(--secondary-color);
}