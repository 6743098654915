/* General Layout */
.layout {
  min-height: 100vh;
  font-family: "Roboto", Arial, sans-serif;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-layout-header {
  background-color: var(--tersiary-color) !important;
}

.login-item {
  margin-left: auto;
}

/* Main Content */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color: var(--body-color);
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  color: var(--text-color);
}

.paragraph {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  max-width: 80%;
  margin-bottom: 32px;
  color: var(--text-color);
}

.buttons-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.company-logo {
  display: block;
  max-width: 100%;
  margin-bottom: 48px;
}

/* Bubbles */
.bubble-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
}

.bubble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bubble-container.left {
  flex-direction: row;
}

.bubble-container.right {
  flex-direction: row-reverse;
}

.bubble-description {
  flex: 1;
  max-width: 50%;
}

.bubble-card {
  margin: 0px 36px;
  text-align: center;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--text-color);
  color: var(--white-color);
  animation: floating 3s ease-in-out infinite;
}

.bubble-card:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

/* Bubble floating animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.home-logo {
  background-image: var(--logo-url);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
  margin-bottom: 48px;
}

/* Buttons */
.demo-button,
.contact-button,
.login-button,
.signup-button {
  height: auto;
  margin: 16px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.antd-content{
  padding: 0px 10vw;
}

.demo-button:hover,
.contact-button:hover,
.login-button:hover,
.signup-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-4px);
}

.demo-button:focus,
.contact-button:focus,
.login-button:focus,
.signup-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.5);
}

/* Core Features */
.core-features-row {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 48px;
}

.core-feature-col {
  flex-basis: calc(50% - 16px);
  margin-bottom: 16px;
}

.ant-typography {
  font-size: 1.1rem;
}

.feature-card {
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  background-color: var(--white-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  margin: 16px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.feature-card:hover .feature-card-title {
  background-color: var(--primary-color);
}

.feature-card-title {
  background-color: var(--text-color);
  color: var(--white-color);
  padding: 16px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  transition: background-color 0.3s ease;
}

.feature-card-description {
  padding: 16px;
  font-size: 16px;
  font-weight: 300;
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 32px;
}

.footer-text {
  text-align: center;
}
