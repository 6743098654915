body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-color)!important;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color:var(--link-text-color);
}

a:hover {
  cursor: pointer!important;
  color: var(--primary-color);
  text-decoration: dashed;
}

.ant-layout {
  background-color: var(--body-color)!important;
}

.icon-selector {
  cursor: pointer;
  color: var(--primary-color);
  margin: .05em .25em;
  font-size: 2.25em;
}

.icon-selector:hover {
  color:var(--white-color);
}

.ant-btn{  
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color:var(--white-color);
}

.ant-btn:hover{  
  border-color: var(--primary-color-alternative);
  background: var(--link-text-hover-color);
  color:white;
}

.ant-btn-primary {  
  background: var(--link-text-color);
  border-color: var(--link-text-color);
  color: var(--white-color);
}

/* .ant-btn-primary :hover {  
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color)!important;
} */

.ant-btn-dangerous {  
  background: var(--danger-color)!important;
  border-color: var(--danger-color)!important;
}

.ant-btn-dangerous :hover {  
  background-color: var(--danger-color-hover)!important;
  border-color: var(--danger-color-hover);
}

.ant-btn-link {
  border-color: transparent!important;
  color:var(--link-text-color)!important;
  background-color:transparent;
}

.ant-btn.ant-btn-link.ant-btn-lg.ant-btn-icon-only:hover {
  color: var(--primary-color)!important;
  background-color: transparent!important;
  border-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5em;
}

.ant-layout-header{
  background-color: var(--primary-color)!important;
  color:var(--white-color)!important;
}

.ant-layout-sider{
  background-color: var(--secondary-color)!important;
}

.ant-menu{
  background-color:var(--tersiary-color) !important;
}

.ant-menu-item {
  color: var(--white-color)!important;
}

.ant-menu-item:hover{
  background-color:var(--alternating-color) !important;
  color:var(--white-color)!important;
}

.ant-menu-item.ant-menu-item-selected {
  /* color:var(--primary-color)!important; */
  background-color: var(--text-color)!important;
}

.ant-menu-item.ant-menu-item-selected:hover{
  background-color: var(--alternating-color)!important;
}

.ant-btn-default {
  color: var(--white-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}