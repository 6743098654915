/* General Layout */
.layout {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
}

/* Header */
.header-menu {
  float: left;
}

.login-button {
  float: right;
  margin-top: 16px;
  margin-right: 16px;
}

/* Main Content */
.antd-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color: #f0f2f5;
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
}

.paragraph {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  max-width: 80%;
  margin-bottom: 32px;
}

.buttons-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.company-logo {
  display: block;
  max-width: 100%;
  margin-bottom: 48px;
}

/* Bubbles */
.bubble-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 48px;
}

.bubble-card {
  text-align: center;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.bubble-card:hover {
  transform: scale(1.1);
}

/* Core Features */
.core-features-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 48px;
}

.core-feature-col {
  flex-basis: calc(50% - 16px);
  margin-bottom: 16px;
}

.feature-card {
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Footer */
.antd-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #001529;
  color: #fff;
  padding: 32px;
}

.footer-text {
  text-align: center;
}
